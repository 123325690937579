<template>
  <div>
    <div v-if="!pdfLoaded && tip" class="load">
      {{ title }}
      <img class="rotate" src="../../../public/images/loading.png" alt="" />
    </div>
    <vue-office-pdf
      v-if="pdfLoaded && !falg"
      :src="pdfUrl"
      @rendered="rendered"
    />

    <img
      v-for="(item, index) in fileURLOther"
      :key="index"
      class="ios_img"
      :src="item"
      alt="合同加载失败"
      v-if="pdfLoaded && falg"
    />
    <div class="button" v-if="pdfLoaded">
      <p style="color: red">如若认为合同无误请点击确认签字</p>
      <button @click="toPath" class="btn">确认签字</button>

      <!-- <el-button type="success" size="medium" @click="toPath" 
        >确认签字</el-button
      > -->
    </div>
    <el-alert
      v-if="!tip"
      title="密匙错误或过期"
      type="error"
      center
      :closable="false"
    >
    </el-alert>
  </div>
</template>

<script>
//引入VueOfficePdf组件
import VueOfficePdf from "@vue-office/pdf";
import api from "@/api/request.js";
import axios from "axios";
import data from "@/servers/public.js";
export default {
  data() {
    return {
      pdfUrl: " ",
      key: null,
      allData: "",
      pdfLoaded: false, // 添加pdfLoaded变量
      title: "加载中",
      tip: true,
      ////
      fileURLOther: [],
      requestUrl: "http://192.168.1.100:9104/file/pdfToImage", //线下
      requestData: {
        url: "https://img.korbanger.com/PDFFile/SmNoB3lxPC60D1yQaYp2eg%3D%3D.pdf",
      },
      falg: false,
    };
  },
  components: {
    VueOfficePdf,
  },
  created() {
    const url = window.location.href; // 获取当前页面的 URL
    const queryString = url.split("?")[1]; // 从 URL 中获取查询字符串

    if (queryString) {
      this.key = queryString; // 将查询字符串赋值给 this.key

      // 安卓PDF预览
      api.updateConstructOrder(this.key).then((res) => {
        console.log(res, "预览");
        if (!res) {
          // this.title = "密匙出错";
          this.tip = false;
          return;
        }
        if (res.code == 200 && res.data) {
          if (localStorage.getItem("contractMessage")) {
            localStorage.removeItem("contractMessage");
          }
          localStorage.setItem("contractMessage", JSON.stringify(res.data));
          const INFO = this.getDeviceInfo();
          console.log(INFO);
          if (INFO == 1 || INFO == 3) {
            this.allData = res.data;
            this.iosPreview(res.data.fileLink);
            console.log(res.data.fileLink);
          } else if (INFO == 0 || INFO == 2) {
            this.pdfUrl = res.data.fileLink;
            this.allData = res.data;
            this.pdfLoaded = true; // 设置pdfLoaded为true
          } else {
            console.log("其他设备");
          }
        } else {
        }
      });
    }
  },
  methods: {
    //获取设备信息
    getDeviceInfo() {
      // 检查是否支持navigator.userAgent
      if (navigator.userAgent) {
        // 获取用户代理字符串
        const userAgent = navigator.userAgent;
        // 检查用户代理字符串中的关键词来判断设备类型
        if (/Android/i.test(userAgent)) {
          return 0;
          console.log("设备类型：Android");
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
          return 1;
          console.log("设备类型：iOS");
        } else if (/Windows/i.test(userAgent)) {
          return 2;
          console.log("设备类型：Windows");
        } else if (/Mac/i.test(userAgent)) {
          return 3;
          console.log("设备类型：Mac");
        } else {
          return 4;
          console.log("设备类型：其他");
        }
        // 获取设备宽度和高度
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        console.log("设备宽度：", screenWidth);
        console.log("设备高度：", screenHeight);
      }
    },
    //安卓预览
    rendered() {
      console.log("渲染完成");
    },
    //确认签字按钮
    toPath() {
      console.log(this.key);
      this.allData.key = this.key;
      if (this.$route.path !== "/signature") {
        this.$router.push({
          path: "/signature",
          query: { allData: this.allData },
        });
      }
    },
    // IOS，PDF预览
    iosPreview(url) {
      this.falg = true;
      axios
        .post(`${data.baseURL}/file/pdfToImage`, { url })
        // .post(this.requestUrl, { url })
        .then((response) => {
          console.log("Response:", response.data);
          const promises = response.data.data.map((item, index) => {
            return new Promise((resolve, reject) => {
              let temporaryAddress = "data:image/jpg;base64," + item;
              const image = new Image();
              image.onload = () => {
                this.fileURLOther.push(temporaryAddress);
                resolve();
              };
              image.onerror = (error) => {
                console.error("Image loading error:", error);
                reject();
              };
              image.src = temporaryAddress;
            });
          });

          Promise.all(promises)
            .then(() => {
              this.pdfLoaded = true;
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    // IOS，PDF预览
    // iosPreview(url) {
    //   this.falg = true;
    //   axios
    //     .post(this.requestUrl, { url })
    //     .then((response) => {
    //       console.log("Response:", response.data);
    //       // Process the response data here
    //       response.data.data.map((item, index) => {
    //         let temporaryAddress = "data:image/png;base64," + item;
    //         this.fileURLOther.push(temporaryAddress);
    //         this.pdfLoaded = true;
    //       });
    //     })
    //     .catch((error) => {
    //       console.error("Error:", error);
    //     });
    // },
  },
};
</script>

<style scoped>
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.load {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.rotate {
  animation: rotation 2s infinite linear;
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
.btn {
  width: 30%;
  /* height: 50%; */
  margin: 4% 0 8% 0;
  border-radius: 20vh;
  border: none;
  background-color: #4dcad6;
  color: #fff;
  padding: 2%;
}
.ifram {
  height: 90vh;
}
.ios_img {
  width: 100%;
  height: 100%;
}
</style>
