<template>
  <div class="signName" :style="{ top: 0, left: differ + 'px' }">
    <!-- <div class="close" @click="close">
      <img src="" alt="关闭按钮" />
    </div> -->
    <p v-if="!isDraw">请本人签名</p>
    <div class="autographBox" ref="canvasHW">
      <!-- <img src="../../../public/images/logo.png" alt="" /> -->
      <canvas
        @touchstart="touchStart($event)"
        @touchmove="touchMove($event)"
        @touchend="touchEnd($event)"
        ref="canvasF"
      ></canvas>
    </div>
    <div class="autographBtn">
      <div @click="overwrite">重签</div>
      <div @click="seaveImages">确定</div>
    </div>
    <div class="hint" :class="{ active: showHint }">请不要签空白名！！！</div>
  </div>
</template>
<script>
import axios from "axios";
import api from "@/api/request.js";
import data from "@/servers/public.js";
export default {
  name: "index",
  data() {
    return {
      differ: document.documentElement.clientWidth,
      canvasF: null,
      canvasHW: null,
      canvasTxt: null,
      points: [],
      isDraw: true,
      startX: 0,
      startY: 0,
      moveY: 0,
      moveX: 0,
      strDataURI: "",
      canvasWidth: 0,
      canvasHeight: 0,
      allData: "",
      showHint: false,
    };
  },
  mounted() {
    this.allData = this.$route.query.allData; // 获取传递的 name 参数值
    console.log(this.allData, "allData");

    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.canvasTxt = this.$refs.canvasF.getContext("2d");
    this.canvasTxt.strokeStyle = "#333";
    this.canvasTxt.lineWidth = "3";
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      let canvas = this.$refs.canvasF;
      canvas.height = this.$refs.canvasHW.offsetHeight;
      canvas.width = this.$refs.canvasHW.offsetWidth;
      this.canvasWidth = canvas.width;
      this.canvasHeight = canvas.height;
    },
    touchStart(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        this.isDraw = true;
        let obj = {
          x: ev.targetTouches[0].clientY,
          y: this.differ - ev.targetTouches[0].clientX - this.differ * 0.1 + 40,
        };
        // console.log(obj);
        this.startX = obj.x;
        this.startY = obj.y;
        this.canvasTxt.beginPath();
        this.points.push(obj);
      }
    },
    touchMove(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientY,
          y: this.differ - ev.targetTouches[0].clientX - this.differ * 0.1 + 40,
        };
        // console.log(obj);
        this.moveY = obj.y;
        this.moveX = obj.x;
        this.canvasTxt.moveTo(this.startX, this.startY);
        this.canvasTxt.lineTo(obj.x, obj.y);
        this.canvasTxt.stroke();
        this.startY = obj.y;
        this.startX = obj.x;
        this.points.push(obj);
      }
    },

    touchEnd(ev) {
      ev = ev || event;
      ev.preventDefault();
      if (ev.touches.length == 1) {
        let obj = {
          x: ev.targetTouches[0].clientY,
          y: this.differ - ev.targetTouches[0].clientX - this.differ * 0.1,
        };
        this.points.push(obj);
        this.points.push({ x: -1, y: -1 });
        this.canvasTxt.closePath();
        this.canvasTxt.fill();
      }
    },
    overwrite() {
      this.canvasTxt.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      this.points = [];
      this.isDraw = false;
    },
    dataURItoBlob(dataURI) {
      // 将 Base64 编码的数据 URI 转换为二进制数据
      let byteString = atob(dataURI.split(",")[1]);
      // 获取 MIME 类型
      let mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // 将二进制数据转换为 ArrayBuffer 对象
      let ab = new ArrayBuffer(byteString.length);
      let ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      // 将 ArrayBuffer 对象转换为 Blob 对象
      let blob = new Blob([ab], { type: mimeString });
      return blob;
    },
    seaveImages() {
      // console.log(this.points.length);
      // this.$alert(this.points.length);
      // debugger;
      if (this.isDraw && this.points.length > 20) {
        this.strDataURI = this.$refs.canvasF.toDataURL("image/png");
        console.log(this.strDataURI);
        this.$emit("autographConfirm", {
          baseCode: this.strDataURI,
        });
        console.log(this.strDataURI);

        let blob = this.dataURItoBlob(this.strDataURI);
        console.log(blob);
        // 将 Blob 对象转换为文件对象
        let file = new File([blob], "filename.png", { type: "image/png" });
        // 将文件添加到 FormData 中
        let formData = new FormData();
        formData.append("file", file);
        // 将额外的表单数据添加到 FormData 中
        formData.append("sig", true);

        // formData.append("key");
        // debugger;
        console.log(formData.get("file")); // 获取文件对象
        // console.log(obj1.get("sign")); // 获取表单数据值
        // console.log(obj1);
        // console.log(data.baseURL);
        axios
          .post(`${data.baseURL}/file/upLoad`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            console.log(response.data);
            let obj = {
              clientId: this.allData.clientId,
              signature: response.data.data,
              orderId: this.allData.orderId,
              version: this.allData.orderVersion,
              key: this.allData.key,
              contractNo: this.allData.contractNo,
            };
            console.log(obj);
            api.customerSign(obj).then((res) => {
              console.log(res);
              if (res.code == 200) {
                let flag;
                if (
                  (this.allData.payMethod &&
                    this.allData.companyId == 100001) ||
                  (this.allData.payMethod == 0 &&
                    this.allData.companyId == 100001)
                ) {
                  flag = true;
                } else {
                  flag = false;
                }
                this.$router.push({
                  name: "success",
                  params: {
                    orderId: this.allData.orderId,
                    paymoney: this.allData.paymentAmount,
                    flag: flag,
                  },
                });

                // }
                // console.log(this.allData.orderId,this.allData.paymentAmount);
                // this.$message("签名成功");
              }
            });
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        this.showHint = true; // 显示提示信息
        setTimeout(() => {
          this.showHint = false; // 一段时间后隐藏提示信息
        }, 1700); // 3秒后隐藏提示信息
        this.overwrite();
      }
    },
    close() {
      this.$emit("close", {
        closeFlag: false,
      });
    },
  },
};
</script>
<style scoped>
.signName {
  position: fixed;
  height: 100vw;
  width: 100vh;
  background-color: #fff;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform-origin: left top;
  z-index: 1000;
}
.hint {
  display: none;
  position: absolute;
  left: 50%;
  color: rgb(81, 81, 81);
  font-size: 18px;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}

.hint.active {
  /* transform: translateY(-50%); */
  display: block;
  position: absolute;
  left: 50%;
  color: rgb(81, 81, 81);
  font-size: 18px;
  transform: translateX(-50%);
  transition: transform 0.3s ease-in-out;
}
p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
}
.close {
  width: 100%;
  height: 10%;
}
.autographBox {
  position: absolute;
  /* top: 10%; */
  left: 0;
  right: 0;
  /* bottom: 20%; */
  /* margin: auto; */
  width: 100%;
  height: 80%;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  background-image: url("../../../public/images/logo.png");
  background-size: 50px 50px;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(255, 255, 255, 0.8); /* 设置透明度为 0.8 */
}
.autographBox canvas {
  width: 100%;
  height: 100%;
}
.autographBox img {
  position: absolute;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #999;
  width: 20%;
  height: 25%;
  /* transform: rotate(90deg); */
  opacity: 0.5;
}
.autographBtn {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-top: 1px solid #e6e6e6;
  border-radius: 0.1rem 0.1rem 0 0;
}
.autographBtn div {
  width: 40%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 2px;
  /* font-size: 0.16rem; */
  color: #333;
  cursor: pointer;
}
</style>
